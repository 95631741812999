import { FC, lazy, createElement } from "react";
import { Outlet, RouteObject } from "react-router-dom";
import DefaultLayout from "../components/layout";
import RequirePermission from "./RequirePermission";
import LoginRoute from "./LoginRoute";

const Home = lazy<FC>(() => import("./home"));
const SchoolsPage = lazy<FC>(() => import("./schools/SchoolsPage"));
const UsersPage = lazy<FC>(() => import("./users/UsersPage"));
const SettingsPage = lazy<FC>(() => import("./settings/SettingsPage"));
const SubjectsPage = lazy<FC>(() => import("./subjects/SubjectsPage"));
const UnitPage = lazy<FC>(() => import("./units/UnitsPage"));
const LoginPage = lazy<FC>(() => import("./sign-in/SignIn"));
const AuthPage =lazy<FC>(() => import("./auth/Auth"))
const ChapterPage =lazy<FC>(() => import("./chapters/ChaptersPage"));
const TopicPage = lazy<FC>(() => import("./topics/TopicPage"));
const TopicsAllPage = lazy<FC>(() => import("./topics/TopicsAllPage"));
const routes: RouteObject[] = [
  {
    element: <DefaultLayout />,
    children: [
      {
        element: <RequirePermission>{createElement(Home)}</RequirePermission>,
        path: "/",
        index: true,
      },
      // {
      //   path: "subjects/:levelName/:subjectId/:unitId/:chapterId",
      //   element: <RequirePermission>{createElement(ChapterPage)}</RequirePermission>,
      // },
      {
        path: "subjects/:levelName",
        children: [
          {
            element: <RequirePermission>{createElement(SubjectsPage)}</RequirePermission>,
            index: true,
          },
          {
            path: ":subjectId",
            children: [
              {
                element: <RequirePermission>{createElement(UnitPage)}</RequirePermission>,
                index: true,
              },
              {
                path: ":unitId",

                children: [
                  { element: <RequirePermission>{createElement(ChapterPage)}</RequirePermission>,
                    index: true
                  },
                  {
                    path: ":chapterId",
                    children: [
                      { element: <RequirePermission>{createElement(TopicsAllPage)}</RequirePermission>,
                        index: true
                      },
                      {
                        path: ":topicId",
                        children: [
                          { element: <RequirePermission>{createElement(TopicPage)}</RequirePermission>,
                            index: true
                          }
                        ],
                      },
                    ],

                  },

                ],
              },
            ],
          },
        ],
      },
      {
        element: <RequirePermission>{createElement(SchoolsPage)}</RequirePermission>,
        path: "schools",
        index: true,
      },
      {
        element: <RequirePermission>{createElement(UsersPage)}</RequirePermission>,
        path: "users",
        index: true,
      },
      {
        element: <RequirePermission>{createElement(SettingsPage)}</RequirePermission>,
        path: "settings",
        index: true,
      },
      {
        element: (
          <LoginRoute>
            {createElement(LoginPage)}
          </LoginRoute>
        ),
        path: "sign-in",
        index: true,
      },
      {
        element: (
          <LoginRoute>
            {createElement(AuthPage)}
          </LoginRoute>
        ),
        path: "auth",
        index: true,
      },
      {
        element: <Outlet />,
        path: "*",
      },
    ],
  },
];

export default routes;


