import {Breadcrumb, Input, Flex, Badge, Avatar,} from "antd"
import { SearchProps } from "antd/es/input";
import React, { FC, useEffect, useState } from "react"
import {ReactComponent as BellIcon} from '../../assets/icons/Bell.svg'
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom"
import { sanitizeBreadcrumb } from "../../helpers/breadcrumbsHelper"

const Header: FC = () => {
  const {Search} = Input
  const location = useLocation()



  const [isDot, setIsDot] = useState<boolean>(false)

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);

  const [breadcrumbs, setBreadcrumbs] = useState<any>([{title: ''}])

  const getNavTitle=(title: string)=>{
    if(!title) return 'Main'
        return  sanitizeBreadcrumb(title[0].toUpperCase() + title.substring(1))
  }

  useEffect(() => {
    const pathArray = location.pathname.substring(1).split('/');
    let pathSoFar = '';
    const newBreadcrumbs = pathArray.map((item, index) => {
      pathSoFar += `/${item}`;
      const isLast = index === pathArray.length - 1;
      return {
        title: isLast
          ? <span>{getNavTitle(item)}</span>
          : <NavLink to={pathSoFar}>{getNavTitle(item)}</NavLink>
      };
    });
    setBreadcrumbs(newBreadcrumbs);
  }, [location]);


  return (
   <React.Fragment>
    <Breadcrumb  separator={">>"} items={breadcrumbs} className={"breadcrumbs"}/>
    <Flex className={"interactive"}>
      <Search placeholder={"Search for admin"} allowClear onSearch={onSearch} size={"large"} className={"search"}/>
      <Badge dot={isDot} color={"#2B78E4"} className={"notification"}>
        <BellIcon/>
      </Badge>
      <Avatar size={48} className={"avatar"}>U</Avatar>
    </Flex>

   </React.Fragment>
  )
}

export  default Header
