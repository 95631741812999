import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { ConfigProvider } from "antd";
import React, { createContext, useContext, FC, memo, PropsWithChildren } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { version } from "../../../package.json";
import introspection from "../../graphql";
import routes from "../../pages";
import theme from "../../themes";
import { getToken, removeToken } from "../../helpers/authHelper"
import { AuthProvider } from "./AuthProvider";

type User = {
  id: string;
  name: string;
  email: string;
  // Additional user fields as needed
};

type AuthContextType = {
  user: User | null;
  login: (email: string, password: string) => void;
  logout: () => void;
  isAuthenticated: () => boolean;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

type ContextProps = {
  app: { version: string };
};

const app: ContextProps["app"] = { version };

const Context = createContext({ app });

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  return <Context.Provider value={{ ...props }}>{children}</Context.Provider>;
};

const useApp: () => ContextProps = () => useContext(Context);

const httpLink = createHttpLink({
  uri: `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}`,
  credentials: "same-origin",
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getToken();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
    removeToken()
  }
});

const client = new ApolloClient({
  link: from([errorLink, authMiddleware, httpLink]),
  connectToDevTools: import.meta.env.DEV,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
});

const router = createBrowserRouter(routes);

const App: FC = memo(() => (
  <ApolloProvider client={client}>
    <AuthProvider>
      <ContextProvider app={app}>
        <ConfigProvider theme={theme}>
          <RouterProvider router={router} />
        </ConfigProvider>
      </ContextProvider>
    </AuthProvider>
  </ApolloProvider>
));

export { useApp };
export default App;
