import { Layout as BaseLayout} from "antd"
import { FC, Suspense, useEffect } from "react"
import {Outlet } from "react-router-dom"
import { Header } from ".";
import LeftMenu from "./LeftMenu";
import { useAuth } from "../app/AuthProvider"



const Layout: FC = () => {
  const {isAuthenticated,  } = useAuth()
  const central = isAuthenticated() ? 'central' : 'central-sign-in'
return (
  <BaseLayout className={"app"}>
    {isAuthenticated() && <LeftMenu/>}
    <BaseLayout className={central}>
      {isAuthenticated() &&       <BaseLayout.Header>
        <Header/>
      </BaseLayout.Header>}

      <BaseLayout className={"main"}>
        <Suspense>
          <Outlet />
        </Suspense>
      </BaseLayout>
    </BaseLayout>
  </BaseLayout>
)
}

export { Layout }
