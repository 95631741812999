import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from "react"
import { useLoginMutation, useMeQuery } from "../../graphql"
import { getToken, removeToken, setToken } from "../../helpers/authHelper"

type User = {
  id: string | null;
  name: string | null;
  surname: string | null;
  email: string | null;
  role: string | null
};

type AuthContextType = {
  user: User | null;
  login: (email: string,  password: string) => Promise<void>;
  logout: () => void;
  isAuthenticated: () => boolean;
};

const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loginMutation] = useLoginMutation();
  const {data: meInfo, loading} = useMeQuery()
  useEffect(() => {
    if (!loading) {
      meInfo?.me ? setUser({
        id: meInfo?.me?.id || null,
        name: meInfo?.me?.name || null,
        surname: meInfo?.me?.surname || null,
        email: meInfo?.me?.email || '',
        role: meInfo?.me?.role?.data?.attributes?.name || null
      }) : setUser(null)
    }
  }, [meInfo?.me])
  const login = async (email: string, password: string): Promise<void> => {
    try {
      const response = await loginMutation({
        variables: {
          input: {
            identifier: email,
            password,
          },
        },
      });
      const token =response?.data?.login?.jwt
      if(!token) return
      setToken(token)
      window.location.href= "/"
    } catch (error) {
      throw error;

    }
  };



  const logout = () => {
    setUser(null);
    removeToken()
  };

  const isAuthenticated = () => {
    return !!getToken()
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated}}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context as AuthContextType;
};

export { useAuth, AuthProvider };
