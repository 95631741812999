
import { EAuth } from "../enums/auth";

export const getToken = (): string | null => {
  return localStorage.getItem(EAuth.authToken);
};

export const setToken = (token: string ) => {
  if (token) {
    localStorage.setItem(EAuth.authToken, token);
  }
};

export const removeToken = (): void => {
  localStorage.removeItem(EAuth.authToken);
};
