import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'src/components/app/AuthProvider';
import React, { ReactNode } from 'react';
interface RequireAuthProps {
  children: ReactNode;
}

const LoginRoute: React.FC<RequireAuthProps> = ({ children }) => {
  const { isAuthenticated } = useAuth()

  return isAuthenticated() ? <Navigate to={"/"} replace /> : children;
};

export default LoginRoute;
