import { useState, type FC } from 'react';
import { Layout as BaseLayout, Divider, Flex, Menu, MenuProps} from "antd"

import { ReactComponent as LogoBig } from "../../assets/icons/LogoBig.svg";
import { ReactComponent as LogoSmall } from "../../assets/icons/LogoSmall.svg";
import  {ReactComponent as SchoolIcon } from '../../assets/icons/School.svg';
import  {ReactComponent as SubjectIcon } from '../../assets/icons/Subject.svg';
import  {ReactComponent as UsersIcon } from '../../assets/icons/Users.svg';
import  {ReactComponent as GearsIcon } from '../../assets/icons/Gears.svg';
import  {ReactComponent as LogoutIcon } from '../../assets/icons/Logout.svg';
import { NavLink } from 'react-router-dom';
import { useAuth } from "../app/AuthProvider"


type MenuItem = Required<MenuProps>['items'][number];

const LeftMenu: FC = () => {
  const {logout} = useAuth()
    const {Sider } = BaseLayout;

    const [collapsed, setCollapsed] = useState(false);

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
      ): MenuItem {
        return {
          key,
          icon,
          children,
          label,
          type,
        } as MenuItem;
      }

  const items: MenuItem[] = [
    getItem('', 'grp1', null, [
      getItem('Subjects', 'sub1', <SubjectIcon/>, [
        getItem(<NavLink to={"subjects/gcse"}>GCSE</NavLink>, '1'),
        getItem(<NavLink to={"subjects/a-level"}> A Level</NavLink>, '2'),
      ]),
      getItem(<NavLink to={"schools"}>Schools</NavLink>, '3', <SchoolIcon />),
      getItem(<NavLink to={"users"}>Users</NavLink>, '4', <UsersIcon />),
    ], 'group'),

    getItem('', 'grp2', null, [
      getItem(<NavLink to={"settings"}>Setting</NavLink>, '6', <GearsIcon />),
      getItem( <NavLink onClick={()=>{
      logout()
      }}  to={"/sign-in"}>Logout</NavLink>, '7', <LogoutIcon />)
    ], 'group'),
  ];

    return (
        <Sider width={"256px"} collapsed={collapsed}>
            <button className={"sider-trigger"} onClick={()=>setCollapsed(prev=>!prev)}></button>
            <Flex className={"logo-black"}>
                {collapsed
                    ? <LogoSmall onClick={() => window.location.assign("/")}/>
                    : <LogoBig onClick={() => window.location.assign("/")}/>
                }
                <Divider className={"logo-driver"}/>
            </Flex>
            <nav>
                <Menu className={"sider-menu"} items={items} mode={"inline"}/>
            </nav>
        </Sider>
    );
}

export default LeftMenu;
